<template>
  <nav aria-label="breadcrumb">
    <div class="breadcrumb">
      <span v-for="(crumb, index) in breadcrumbs" :key="index" class="breadcrumb-item" :class="{ active: index === breadcrumbs.length - 1 }">
        <a v-if="index !== breadcrumbs.length - 1" :href="crumb.to">{{ crumb.text }}</a>
        <span v-else>{{ crumb.text }}</span>
      </span>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  margin-bottom: 20px;
  list-style: none;
  border-radius: 4px;
}
.breadcrumb-item {
  color: #222 !important;
  a {
    color: #222 !important;
    text-decoration: none;
    &:hover {
      color: #20a8ab !important;
    }
  }
  &::before {
  content: ">";
  padding: 0 6px;
  color: #222;
  }
}
</style>
