<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://gbwhatsapks.net/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/gb-logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://gbwhatsapks.net/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://gbwhatsapks.net/" aria-current="page">GB WhatsApp</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/download-gbwhatsapp/">Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/gb-whatsapp-old-versions/">Old Versions</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/blogs/">Blogs</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <section id="section-1" class="section-content">
            <Breadcrumb :breadcrumbs="breadcrumbs" />

            <h1 class="section-1-blog-head">
              What is GB WhatsApp? Key Features of GB WhatsApp You Want to Know
            </h1>

            <div class="lazy-picture-container writer-banner full-width">
              <picture><img width="auto" height="auto" alt="What is GB WhatsApp? Key Features of GB WhatsApp You Want to Know" src="../assets/key-feature-gb-whatsapp.webp"></picture>
            </div>
            <p class="writter-content">
             <a href="https://gbwhatsapks.net/" class="jump-url">GB WhatsApp</a>is a <strong>third-party modified version</strong> of the official <strong>WhatsApp</strong> app. It offers additional features and customization options that are not available in the official version. Many users prefer GB WhatsApp for its advanced privacy settings, UI customization, and enhanced file-sharing capabilities. 
            </p>

            <h2 class="intro-title blog">
              Here are some key features of GB WhatsApp that users find attractive:
            </h2>

            <h3>
               1. Customization Options
            </h3>
            <p class="writter-content">
              Themes: GB WhatsApp allows users to apply custom themes, changing the app's appearance significantly.Font Style and Size: Users can adjust the font style, size, and color, personalizing their experience.
            </p>
            <h3>
              2. Increased File Sharing Limits
            </h3>
            
            <p class="writter-content">
              You can send larger files compared to the official WhatsApp. For example, GB WhatsApp allows you to send video files up to 50MB, while WhatsApp has a 16MB limit.
            </p>

            <h3>
              3. Privacy Features
            </h3>
            <ul>
              <li>Hide Blue Tick (Read Receipt): You can disable the blue ticks that appear when a message is read.</li>
              <li>Hide Second Tick: You can hide the second checkmark indicating that the message has been delivered.</li>
              <li>Anti-Revoke Messages: GB WhatsApp allows you to read deleted messages, unlike WhatsApp, where messages disappear after they're deleted by the sender.</li>
            </ul>

            <h3>
              4. Dual WhatsApp Accounts
            </h3>
            <p class="writter-content">
              GB WhatsApp allows you to run two WhatsApp accounts on the same device, which is useful for users who have personal and work numbers.
            </p>
            <h3>
              5. Extended Status Character Limit
            </h3>
            <p class="writter-content">
              WhatsApp limits status updates to 139 characters, but GB WhatsApp extends this limit to 255 characters.
            </p>

            <h3>
              6. Customizable Notifications
            </h3>
            <p class="writter-content">
              GB WhatsApp lets you customize your notifications with different tones, vibration settings, and pop-up notifications for individual contacts.
            </p>

            <h3>
              7. Advanced Media Sharing
            </h3>
            <p class="writter-content">
              GB WhatsApp lets you share images and videos in their original quality, without compressing them.
            You can also send a larger number of images or videos at once, up to 90 items, compared to the official app's 
            </p>

            <h3>
              8. Built-in App Lock
            </h3>
            <p class="writter-content">
              GB WhatsApp has a built-in feature to lock the app with a PIN or password, offering additional security.
            </p>

            <h3>
              9. Broadcast Message Feature
            </h3>
            <p class="writter-content">
              GB WhatsApp allows you to broadcast messages to more people than WhatsApp’s official version, which is restricted to 256 contacts.
            </p>

            <h3>
              10. Anti-Ban Feature
            </h3>
            <p class="writter-content">
              Although using GB WhatsApp is against WhatsApp's terms of service, this modified version has built-in anti-ban features that help users avoid getting banned (although it’s not guaranteed).
            </p>

            <h3>
              11. Auto-Reply
            </h3>
            <p class="writter-content">
              GB WhatsApp lets you set an auto-reply for messages when you are not available, which can be a helpful feature for businesses or busy users.
            </p>
            <h3>
              12. Send Messages to Unsaved Contacts
            </h3>
            <p class="writter-content">
              With GB WhatsApp, you can send messages to contacts without saving their number in your phone's contact list.
            </p>
            <h3>
              13. Call Blocker
            </h3>
            <p class="writter-content">
              GB WhatsApp also provides a feature to block calls from specific contacts without blocking them entirely.
            </p>

            <h2 class="intro-title blog">
              Final Verdict
            </h2>
            <p class="writter-content">
              GB WhatsApp is popular for its extra features, but it comes with security risks and the possibility of being banned. If you want to try it, 
              you can use a new account.<a href="https://gbwhatsapks.net/how-to-download-gbwhatsapp-apk-safely/" class="jump-url"> How to Download GBWhatsApp APK Safely?</a>
               Download the APK – Go to our GBWhatsApp Download page to get the latest version securely.
            </p>

            <PostPagination :currentPage="4" />

          </section>
        </main>
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="https://gbwhatsapks.net/disclaimer/">Disclaimer</a>
          |
          <a href="https://gbwhatsapks.net/about-us/">About Us</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="https://gbwhatsapks.net/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>
  <div v-if="$global.isMobileDevice()" style="height: 50px" aria-hidden="true" class="wp-block-spacer" />

  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" href="https://gbwhatsapks.net/download-gbwhatsapp/">
    Download GBWhatsapp APK
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import PostPagination from '@/components/PostPagination.vue';
import Breadcrumb from '@/components/BreadCrumb.vue';

export default {
  name: 'Home',
  components: {
    PostPagination,
    Breadcrumb,
  },
  data() {
    return {
      pageName: 'home',
      showapk: false,

      apk: null,
      from: 'gb',
      filename: 'gb',
    };
  },
  computed: {
    breadcrumbs() {
      return this.$route.matched.reduce((acc, route) => {
        const crumbs = route.meta.breadcrumb || [];
        return acc.concat(crumbs);
      }, []);
    }
  },
  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }
  },
  methods: {
    downloadURL() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },

    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    jump(url) {
      window.location.href = url;
    },
  },
};
</script>
