<template>
  <div class="pagination">
    <!-- 上一页 -->
    <a 
      v-if="currentPage > 0 && blog.bloglist[currentPage - 1]" 
      class="prev" 
      :href="blog.bloglist[currentPage - 1].link || '#'" 
    >
      <div class="instruction">
        <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M31 36L19 24L31 12" stroke="#000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <small>Previous</small>
      </div>
      <div class="page-title">
        {{ blog.bloglist[currentPage - 1]?.title || 'No Title' }}
      </div>
    </a>

    <!-- 下一页 -->
    <a 
      v-if="currentPage < totalPages - 1 && blog.bloglist[currentPage + 1]" 
      class="next" 
      :href="blog.bloglist[currentPage + 1].link || '#'" 
    >
      <div class="instruction">
        <small>Next</small>
        <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 12L31 24L19 36" stroke="#000" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div class="page-title">
        {{ blog.bloglist[currentPage + 1]?.title || 'No Title' }}
      </div>
    </a>
  </div>
</template>

<script>
import blog from '@/documents/blogList.js'

export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      blog: blog,
      totalPages: blog.bloglist.length,

    }
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;

  .instruction {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 8px;

    small {
      font-weight: 600;
    }
  }

  .prev,
  .next {
    position: relative;
    color: #222 !important;
    padding: 20px 0 40px;
    padding-right: 40px;
    padding-left: 10px;
    text-decoration: none;

    .page-title {
      font-weight: 400;
      margin-left: 10px;
      font-size: 19px;
    }

    &:hover {
      color: #20a8ab !important;
      .instruction {
        svg path {
          stroke: #20a8ab;
        }
      }
    }
  }

  .prev::after {
    position: absolute;
    content: '';
    top: 25%;
    right: 0;
    width: 1px;
    height: 50%;
    background: #a0aec0;
  }

  .next {
    text-align: right;
    padding-right: 10px;
    padding-left: 40px;
    grid-column-start: 2;
    .page-title {
      margin-left: 0px;
      margin-right: 10px;
    }
    .instruction {
      justify-content: flex-end;
    }
  }


}
</style>
